import { useSession } from '../session'
import { useLocale } from '../locale'
import PageHeader from '../components/PageHeader'
import { UserHelpLinkItem } from '../components/SiteHeader/NavLinks'


const Homepage = (): JSX.Element => {
  const { l } = useLocale()
  const { isAuthenticated } = useSession()

  return (
    <article>
      <PageHeader title={l.homepage.welcome} />
      {!isAuthenticated() && (
        <p>{l.homepage.loginRequest}</p>
      )}
      <h3 style={{ paddingTop: '40px' }}>{l.homepage.reportPreparationTitle}</h3>
      <p
        style={{
          whiteSpace: 'pre-line',
          paddingTop: '10px',
          width: '70%',
          overflowWrap: 'break-word',
        }}
      >
        {l.homepage.reportPreparationText}
      </p>
      <h3 style={{ paddingTop: '40px' }}>{l.homepage.reportFormsTitle}</h3>
      <UserHelpLinkItem path={l.homepage.reportFormsLink} label={l.homepage.reportFormsLink} />
      <h3 style={{ paddingTop: '40px' }}>{l.homepage.additionalInfoTitle}</h3>
      <UserHelpLinkItem path={l.homepage.additionalInfoLink} label={l.homepage.additionalInfoLinkText} />
    </article>
  )
}

export default Homepage
