import defaultLocale from './default'


const appName = 'Projekty FIT'

const thesisMainState: (typeof defaultLocale)['thesis']['mainState'] = {
  draft: 'Koncept',
  proposed: 'Navržená',
  assigned: 'Zadaná',
  review: 'Čeká na posudky',
  readyForDefence: 'Připravená k obhajobě',
  evaluated: 'Ohodnocená',
  archived: 'Archivovaná',
  deleted: 'Smazaná',
}

const thesisLanguageOptions: (typeof defaultLocale)['thesis']['languageOptions'] = {
  cs: 'Čeština',
  en: 'Angličtina',
  sk: 'Slovenština',
}

const cs: (typeof defaultLocale) = {
  appName,
  configuration: {
    configuration: 'Nastavení',
    currentSemester: 'Aktuální semestr',
    editConfiguration: 'Editace nastavení',
    errorGettingConfiguration: 'Nastala chyba při stahování nastavení',
    loadingConfiguration: 'Načítám nastavení',
    reportDeadlineBachelor: 'Termín pro odevzdání posudků bakalářské práce',
    reportDeadlineMaster: 'Termín pro odevzdání posudků diplomové práce',
  },
  errorMessages: {
    networkError: (url: string): string => `Nastala síťová chyba při dotazu na '${url}'.`,
    api: {
      errorGettingData: 'Nastala chyba při načítání dat',
      forbidden: (objectType: string, id: string): string => (
        `Nemáte právo přistupovat k objektu '${objectType}' s identifikátorem '${id}'`
      ),
      notFound: (objectType: string, id: string): string => (
        `Objekt '${objectType}' s identifikátorem '${id}' nebyl nalezen`
      ),
      defaultGetList: (objectType: string): string => (
        `Nastala chyba při získávání seznamu objektů typu '${objectType}'`
      ),
      defaultCreate: (objectType: string): string => (
        `Nastala chyba při vytváření objektu '${objectType}'`
      ),
      defaultPatch: (objectType: string, id: string): string => (
        `Nastala chyba při aktualizaci objektu '${objectType}' s identifikátorem '${id}'`
      ),
      defaultPatchList: (objectType: string): string => (
        `Nastala chyba při aktualizaci seznamu objektů typu '${objectType}'`
      ),
      defaultDelete: (objectType: string, id: string): string => (
        `Nastala chyba při mazání objektu '${objectType}' s identifikátorem '${id}'`
      ),
      defaultDownload: (objectType: string, id: string): string => (
        `Nastala chyba při stahování objektu '${objectType}' s identifikátorem '${id}'`
      ),
      defaultUpload: (objectType: string, id: string): string => (
        `Nastala chyba při nahrávání souboru k objektu '${objectType}' s identifikátorem '${id}'`
      ),
      defaultThesisActionsErrorMessage: 'Žádost o provedení akce selhala.',
    },
  },
  successMessages: {
    api: {
      defaultCreate: (objectType: string): string => (
        `Byl vytvořen objekt typu '${objectType}'`
      ),
      defaultPatch: (objectType: string, id: string): string => (
        `Byly uloženy změny objektu '${objectType}' s identifikátorem '${id}'`
      ),
      defaultDelete: (objectType: string, id: string): string => (
        `Byl smazán objekt typu '${objectType}' s identifikátorem '${id}'`
      ),
    },
  },
  homepage: {
    welcome: `Vítejte v aplikaci ${appName}!`,
    loginRequest: 'Pro přístup k dalším akcím se prosím přihlaste.',
    reportFormsTitle: 'Formuláře na posudky',
    reportFormsLink: 'https://campuscvut.sharepoint.com/:f:/s/Team-18301-projekty-sto-support/EoaXM2THRzlIkT9RaiGoWN4BPzddXob4HICyLQoZz7d7nQ?e=6jQz5J',
    reportPreparationText: `
Posudky ZP pište stejně jako dříve (vyberte si studenta atd.),
rozdíl je pouze v tom, že práci si musíte zobrazit v KOSu.
Po vytvoření posudku klikněte na "Zkontrolovat PDF", zobrazené pdf uložte a uploadujte do KOSu.

V případě, že by došlo k jakýmkoli problémům (toto je dočasné řešení),
použijte šablonu mimo ProjectFIT z níže uvedeného linku,
vygenerujte pdf a uploadujte jej stejným způsobem.
`,
    reportPreparationTitle: 'Příprava posudků závěrečných prací',
    additionalInfoTitle: 'Další informace a návody naleznete na:',
    additionalInfoLink: 'https://courses.fit.cvut.cz/SZZ/prace/index.html#_odevzd%C3%A1n%C3%AD-posudk%C5%AF-vedouc%C3%ADmi-a-oponenty',
    additionalInfoLinkText: 'Závěrečná práce • SZZ • FIT ČVUT Course Pages',
  },
  userSettings: {
    title: 'Uživatelské nastavení',
    notificationSettings: {
      title: 'Nastavení upozornění',
      goTo: 'Zobrazit nastavení upozornění',
    },
    labels: {
      noticeEmailsEnabled: 'Chci dostávat oznámení také e-mailem',
      taskEmailsEnabled: 'Chci dostávat úkoly také e-mailem',
    },
    loading: 'Načítám uživatelská nastavení',
    noUserSettings: 'Nemáte žádná uživatelská nastavení',
    requestMessages: {
      success: 'Nastavení uloženo',
      error: 'Nastala chyba při ukládání nastavení',
      saving: 'Ukládám nastavení…',
    },
  },
  langSwitcher: {
    tooltip: 'Změnit jazyk aplikace',
  },
  person: 'Osoba',
  personSearchBox: {
    error: 'Nastala chyba při vyhledávání',
    loading: 'Vyhledávám osoby…',
    selectNone: 'Zrušit výběr',
    noResults: 'Žádná osoba nenalezena',
    noSpecialization: 'Nemá zvolenou specializaci',
    selectedPerson: 'Vybraná osoba',
    hasThesisAssigned: 'Již má přiřazenou práci',
  },
  siteHeader: {
    theses: 'Závěrečné práce',
    configuration: 'Nastavení',
    createThesis: 'Vytvořit zadání',
    home: 'Úvodní stránka',
    seeMyTheses: 'Mé práce',
    searchTheses: 'Vyhledávání prací',
    searchThesesAsOfficer: 'Vyhledávání referenta SZZ',
    seeNotices: 'Zobrazit oznámení',
    seeTasks: 'Zobrazit úkoly',
    profile: 'Profil',
    logout: 'Odhlásit se',
    login: 'Přihlásit se',
    youAreNotLoggedIn: 'Nejste přihlášen',
    expandMenu: 'Otevřít menu',
    closeMenu: 'Zavřít menu',
    expand: 'Otevřít',
    close: 'Zavřít',
    navigation: 'Navigace webu',
    topics: 'Témata',
    createTopic: 'Vytvořit téma',
    myTopics: 'Má témata',
    searchTopics: 'Vyhledávání témat',
  },
  notification: {
    errorMessages: {
      getStatus: 'Nastala chyba při získávání stavu notifikací',
      getMetadata: 'Nastala chyba při načítání metadat notifikací',
    },
    notification: 'Notifikace',
    notices: {
      title: 'Oznámení',
      loading: 'Načítám oznámení…',
      errorGetAll: 'Nastala chyba při stahování oznámení.',
      noResults: 'Momentálně nemáte žádná oznámení.',
    },
    tasks: {
      title: 'Úkoly',
      loading: 'Načítám úkoly…',
      errorGetAll: 'Nastala chyba při stahování úkolů.',
      noResults: 'Momentálně nemáte žádné úkoly.',
    },
  },
  study: 'Studium',
  toasts: {
    close: 'Zavřít vyskakovací upozornění',
  },
  // "Bakalářský/magisterský studijní program".
  studyDegree: {
    bachelor: 'Bakalářský',
    master: 'Magisterský',
  },
  thesis: {
    thesis: 'Závěrečná práce',

    assignment: 'Zadání',
    description: 'Popis',
    titleInLanguage: {
      cs: 'Název česky',
      en: 'Název anglicky',
    },
    thesisType: 'Typ práce',
    thesisTypeOption: {
      bachelor: 'Bakalářská práce',
      master: 'Diplomová práce',
    },
    thesisTypeOptionInitialism: {
      bachelor: 'BP',
      master: 'DP',
    },
    thesisLanguage: 'Jazyk práce',
    language: 'Jazyk',
    supervisorWillProposeReviewer: 'Oponenta/ku navrhne vedoucí sám/sama',
    validUntil: 'Platné do',
    validUntilTooltip: 'Práce je platná do tohoto semestru',
    languageOptions: thesisLanguageOptions,
    licenseType: 'Licence',
    licenseTypeOptions: {
      open: 'Otevřená',
      restricted: 'Omezená',
    },
    archiveThesis: 'Archivovat práci',

    theses: 'Závěrečné práce',
    loadingTheses: 'Načítám závěrečné práce',
    errorGettingTheses: 'Nastala chyba při stahování závěrečných prací',
    noTheses: 'Žádné závěrečné práce',
    details: 'Detaily',

    edit: 'Upravit',
    download: 'Stáhnout',

    secondaryOptions: 'Další akce',
    loadingThesis: 'Načítám závěrečnou práci',
    errorGettingThesis: 'Nastala chyba při stahování závěrečné prace',

    selectLicenseTypePrompt: 'Vyberte prosím licenci',
    selectLanguagePrompt: 'Vyberte prosím jazyk',

    editAssignment: 'Editace zadání',
    delete: 'Smazat',
    errorDeletingThesis: 'Nastala chyba při mazání závěrečné práce',
    save: 'Uložit',
    errorSavingThesis: 'Nastala chyba při ukládání závěrečné práce',
    proposeActionsButton: 'Navrhnout ke schválení',
    proposeActionsSuccessMessage: 'Odeslání ke schválení uspělo',
    proposeHelp: 'Abyste mohli práci navrhnout ke schválení, vyplňte všechna pole, přidělte studenta a práci uložte.',
    proposeActionsModal: {
      proposeActionsPositiveButton: 'Ano',
      proposeActionsTitle: 'Opravdu chcete odeslat návrh ke schválení?',
    },
    officerPrintValidationModal: {
      title: 'Opravdu chcete schválit výtisk?',
      actionNo: 'Ne, zrušit',
      actionYes: 'Ano, schválit',
    },
    deleteActionModal: {
      title: 'Smazat zadání',
      text: 'Opravdu chcete smazat toto zadání? Tato akce je nevratná.',
      positiveButton: 'Ano',
    },
    reviewerProposalAcceptancePanel: {
      title: 'Přijetí oponentury',
      description: 'Přijímáte návrh oponovat tuto práci?',
    },

    creation: 'Tvorba zadání',
    errorCreatingThesis: 'Nastala chyba při tvorbě závěrečné práce',

    backupRestoreDialog: {
      title: 'Nalezena záloha',
      text: 'Byla nalezena záloha poslední neuložené verze. Chcete obsah formuláře obnovit ze zálohy?',
      forget: 'Zahodit změny',
      restore: 'Obnovit změny',
    },

    state: 'Stav',
    states: 'Podstavy',
    substate: {
      'proposed.awaitsSpecOfficerApproval': 'Čeká na schválení referenta/ky specializace',
      'proposed.revision': 'Vráceno k revizi',
      'proposed.awaitsAssigneeAcceptance': 'Čeká na přijetí studenta/ky',
      'proposed.acceptedByAssignee': 'Přijato studentem/kou',
      'proposed.rejectedByAssignee': 'Odmítnuto studentem/kou',
      'changeable.assigned.reviewer.pending': 'Čeká na přiřazení oponenta/ky',
      'changeable.assigned.reviewer.assigned': 'Oponent/ka přiřazen/a',
      'changeable.review.supervisor.pending': 'Čeká na posudek vedoucí/ho',
      'changeable.review.supervisor.submitted': 'Posudek vedoucí/ho odevzdán',
      'changeable.review.reviewer.pending': 'Čeká na posudek oponenta/ky',
      'changeable.review.reviewer.submitted': 'Posudek oponenta/ky odevzdán',
    },

    people: 'Lidé',
    reviewer: 'Oponent',
    student: 'Student',
    supervisor: 'Vedoucí',
    unassigned: 'Nepřidělen',

    specialization: 'Specializace',
    status: 'Stav',
    title: 'Název',
    type: 'Typ',
    approvalDate: 'Datum schválení',
    approvalDateShort: 'Schváleno',
    evaluationYear: 'Rok obhajoby',

    assignee: {
      student: 'Student',
      noStudentAssigned: 'Zadání nemá přiděleného studenta',
      placeholder: 'Zadejte jméno nebo login studenta',
      searchingStudents: 'Vyhledávám studenty…',
      errorSearchingStudents: 'Nastala chyba při vyhledávání studentů',
      noStudentFound: 'Žádný student nenalezen',
      assignedStudent: 'Přiřazený student',
      clearAssignee: 'Zrušit přiřazení',
      edit: 'Upravit',
      close: 'Zavřít',
    },
    mainState: thesisMainState,

    submission: {
      errorMessages: {
        attachmentAlreadyExists: (name: string): string => `Příloha s názvem „${name}“ již existuje`,
        attachmentFilenameShouldMatch: (name: string): string => (
          `Nahrávání této přílohy by mělo být navázáno souborem „${name}“`
        ),
        attachmentSizeExceedsLimit: (size: number | string): string => (
          `Soubor překračuje maximální povolenou velikost (${size})`
        ),
        onlyAssigneeCanSubmit: 'Práci může odevzdat pouze přiřazený student',
        submitOnlyAssignedStateTheses: `Lze pouze odevzdávat práce ve stavu '${thesisMainState.assigned}'`,
        thesisNotSubmitted: 'Práce nebyla dosud odevzdána v KOS',
        invalidURL: 'Musí být platné URL',
        uploadFailure: 'Při nahrávání příloh se vyskytla chyba',
      },
      successMessages: {
        save: 'Závěrečná práce byla úspěšně uložena',
        submit: 'Závěrečná práce byla úspěšně odeslána',
        upload: 'Soubory příloh byly úspěšně nahrány',
      },
      title: 'Odevzdání práce',
      subTitle: 'Údaje k závěrečné práci',
      view: {
        title: 'Odevzdání',
        label: {
          abstract: 'Abstrakt',
          keywords: 'Klíčová slova',
          thesisFile: 'Práce',
          attachments: 'Přílohy',
          dspace: 'Stránka práce na DSpace',
        },
        thesisFileTitle: 'Odevzdaná práce',
      },
      form: {
        label: {
          inputs: {
            abstractCs: 'Abstrakt česky',
            abstractEn: 'Abstrakt anglicky',
            keywordsCs: 'Klíčová slova česky',
            keywordsEn: 'Klíčová slova anglicky',
          },
          files: {
            finalText: 'PDF soubor závěrečná práce',
          },
          attachments: {
            title: 'Přílohy',
            noResult: 'Práce nemá žádné přílohy',
            attachment: {
              titleCs: 'Název česky',
              titleEn: 'Název anglicky',
              type: 'Typ',
              href: 'URL',
              file: 'Soubor',
            },
            uploadState: {
              complete: 'Hotovo',
              error: 'Chyba při nahrávání',
              waiting: 'Čeká',
            },
          },
        },
        attachmentType: {
          sourceCode: {
            option: 'zdrojový kód',
            title: 'zdrojový kód',
          },
          dataset: {
            option: 'datová sada',
            title: 'datová sada vytvořená nebo použitá v rámci práce',
          },
          website: {
            option: 'webová stránka',
            title: 'webové stránky projektu',
          },
          demo: {
            option: 'funkční ukázka',
            title: 'funkční ukázka vytvořeného softwarového díla',
          },
          paper: {
            option: 'vědecký článek',
            title: 'vědecký článek publikovaný v rámci práce',
          },
          other: {
            option: 'vlastní typ přílohy',
            title: '',
          },
        },
        help: {
          keywords: 'slova oddělte čárkou',
          thesisFile: 'velikost souboru do 50 MiB',
        },
        modal: {
          title: 'Opravdu chcete odevzdat práci?',
          description: 'Obsah před odesláním zkontrolujte. Po odeslání jej už nebude možné dále upravovat.',
          requiredInfo: '* Pole je povinné k odevzdání práce',
        },
        submitButton: 'Odevzdat práci',
        attachmentButtons: {
          addLink: 'Přidat odkaz',
          addFile: 'Přidat soubor',
          remove: 'Odebrat přílohu',
        },
      },
    },

    supersession: {
      confirmationTitle: 'Archivovat zadání a vytvořit duplikát, který jej nahradí?',
      errorMessage: 'Chyba při nahrazování zadání duplikátem',
      loadingMessage: 'Vytváření duplikátu pro nahrazení zadání...',
      successMessage: 'Zadání úspěšně nahrazeno',
    },

    reviewerPageTitle: 'Kandidáti na oponenturu závěrečné práce',
    candidatesList: 'Seznam kandidátů',

    actionTitle: {
      ARCHIVE: 'Závěrečná práce archivována',
      ASSIGN_DIRECTLY: 'Závěrečná práce přidělena napřímo',
      ASSIGNEE_ACCEPT: 'Přijato studentem',
      ASSIGNEE_REJECT: 'Zamítnuto studentem',
      CREATE: 'Závěrečná práce vytvořena',
      DELETE: 'Závěrečná práce smazána',
      OFFICER_APPROVE: 'Schváleno referentem/kou',
      PROPOSE: 'Navržena ke schválení',
      REVISE: 'Závěrečná práce revidována',
      ASSIGN_REVIEWER: 'Přidělen oponent',
      REQUEST_APPROVAL: 'Požádáno o kontrolu výtisků práce',
      APPROVE_PRINTS: 'Výtisky práce potvrzeny',
      SUBMIT_REVIEWER_REPORT: 'Posudek oponenta/ky nahrán',
      SUBMIT_SUPERVISOR_REPORT: 'Posudek vedoucí/ho nahrán',
      EVALUATE: 'Závěrečná práce ohodnocena',
      SUPERSEDE: 'Závěrečná práce nahrazena jinou',
      WITHDRAW: 'Zadání staženo k přepracování',
    },

    reports: {
      thesisStudentLabel: 'Student:',
      thesisTitleLabel: 'Název práce:',
      thesisBranchLabel: 'Obor / specializace',
      supervisorReportTitle: 'Hodnocení vedoucího závěrečné práce',
      supervisorReportNameLabel: 'Vedoucí práce:',
      reviewerReportTitle: 'Posudek oponenta závěrečné práce',
      reviewerReportNameLabel: 'Oponent práce:',
      choiceInfo: (length: number): string => `Způsob hodnocení: následující škálou 1 až ${length}.`,
      pointsInfo: 'Způsob hodnocení: bodové hodnocení 0 až 100 bodů (známka A až F).',
      evaluationLabel: 'Hodnocení',
      commentLabel: 'Komentář',
      language: 'Jazyk posudku',
      saveSuccessToast: 'Posudek byl uložen',
      checkPdfButton: 'Zkontrolovat PDF',
      submitButton: 'Označit posudek za hotový',
      submitModalTitle: 'Opravdu chcete označit posudek za hotový?',
      submitModalText: 'Text posudku před dokončením zkontrolujte. Po označení za hotový jej už nebude možné dále upravovat.',
      supervisorSubmitModalText: 'Souhlasí text zadání ve výsledném PDF se schváleným textem zadání práce?',
      submitModalCancelButton: 'Zpět',
      submitSuccessToast: 'Posudek byl úspěšně dokončen',
      submitSupervisorInfo: 'Posudek lze označit za hotový po potvrzení, že zadání v odevzdané závěrečné práci se shoduje '
        + 'se zadáním v systému ProjectsFIT.',
      submitRequiredInfo: '* Pole je povinné k označení posudku za hotový',

      loadingReport: 'Načítám posudek',

      errorMessages: {
        load: 'Nastala chyba při načítání posudku práce',
        save: 'Nastala chyba při ukládání posudku práce',
        submit: 'Nastala chyba při oznáčení posudku za hotový',
      },

      download: 'Stáhnout posudek',

      evaluationCard: {
        loading: 'Načítám posudky práce',
        errorGettingReports: 'Nastala chyba při získávání posudků práce',
        noReports: 'Tato práce nemá žádné posudky',
        reportType: {
          reviewer: 'Posudek oponenta/ky',
          supervisor: 'Posudek vedoucí/ho',
        },
        displayLinkTitle: {
          reviewer: 'Zobrazit posudek oponenta/ky',
          supervisor: 'Zobrazit posudek vedoucí/ho',
        },
        pointsAbbrev: 'b.',
        finalLabel: 'Finální',
      },

      deadline: {
        header: 'Termín pro odevzdání posudku',
        loadingMessage: 'Načítám termín pro odevzdání posudku práce',
        loadingError: 'Nastala chyba při načítání termínu pro odevzdání posudku práce',
      },
    },
  },
  thesesFilters: {
    withoutReviewer: 'Bez oponenta',
  },
  errorGettingThesesMetadata: 'Nastala chyba při načítání metadat závěrečných prací',
  thesisReviewerProposal: {
    actions: {
      title: {
        applied: 'Přihlásil/a jste se k oponentuře této práce',
        proposeMyself: 'Můžete se přihlásit o pozici oponenta/ky',
        proposedBy: (name: string): string => `${name} Vás navrhl/a na oponenta/ku této práce`,
        reviewerProposal: 'Přihlášení k oponentuře',
      },
      proposeMyself: 'Přihlásit se k oponentuře',
      proposalAccepted: 'Jste přihlášen/a',
      accept: 'Přijmout',
      cancel: 'Odhlásit se',
      reject: 'Odmítnout',
    },
    search: {
      title: 'Navrhnout nového/ou kandidáta/ku na oponenta/ku',
      placeholder: 'Hledat…',
      searching: 'Vyhledávám osoby…',
      error: 'Nastala chyba při vyhledávání osob',
      noResults: 'Žádná osoba nebyla nalezena',
    },
    errorMessages: {
      propose: 'Nastala chyba při navrhování kandidáta/ky na oponenta/ku',
      accept: 'Nastala chyba při schvalování návrhu kandidáta/ky na oponenta/ku',
      cancel: 'Nastala chyba při odebírání kandidáta/ky na oponenta/ku',
    },
    successMessages: {
      propose: (name: string): string => `${name} byl/a navržen/a na kandidáta/ku na oponenta/ku`,
      proposeMyself: 'Byl/a jste přihlášen/a ke kandidatuře na oponenta/ku',
      accept: 'Vaše kandidatura na oponenta/ku byla přijata',
      cancel: 'Vaše kandidatura na oponenta/ku byla stažena',
    },
  },
  thesisReviewerProposalsList: {
    errorMessages: {
      load: 'Nastala chyba při načítání seznamu kandidátů na oponenturu',
      reject: 'Nastala chyba při zamítání kandidáta na oponenturu',
    },
    successMessages: {
      accept: 'Kandidatura na oponenturu byla schválena',
      reject: 'Kandidatura na oponenturu byla zamítnuta',
      cancel: 'Kandidát na oponenturu byl odebrán',
    },
    emptyResultsMessage: 'Žádné návrhy na oponenturu',
    reviewerAcceptanceCaption: {
      accepted: 'Ano',
      pending: '',
      rejected: 'Ne',
    },
    columnCaptions: {
      name: 'Jméno',
      reviewerAcceptance: 'Přijato kandidátem',
      specOfficerApproval: 'Schváleno katedrou',
      proponent: 'Autor návrhu',
    },
    actions: {
      approve: 'Schválit',
      disapprove: 'Zamítnout',
      remove: 'Odebrat kandidáta',
      canceledByProponent: 'Staženo navrhovatelem',
      rejectedByReviewerCandidate: 'Odmítnuto kandidátem',
      rejectedBySpecOfficer: 'Zamítnuto referentem',
      waitingForSpecOfficerApproval: 'Čeká na schválení referentem',
      waitingForCandidatesAcceptance: 'Čeká na kandidátovo přijetí',
    },
  },
  navigation: {
    goBack: 'Přejít zpět',
    displayMore: 'Zobrazit více',
    displayLess: 'Zobrazit méně',
    invalidPath: {
      title: 'Zadaná adresa není validní',
      currentPath: 'Zadaná adresa',
      correctFormat: 'Očekávaný formát',
    },
  },
  defaultStatus: 'Nastala chyba',
  pageStatus: {
    403: {
      title: 'Přístup odepřen',
      message: 'K této akci nebo stránce nemáte dostatečná oprávnění',
    },
    404: {
      title: 'Stránka nebyla nalezena',
      message: 'Nebyla nalezena stránka s cestou',
    },
  },
  pagination: {
    loadMore: 'Načíst další',
  },
  modals: {
    cancelCaption: 'Zrušit',
    closeModal: 'Zavřít dialog',
    defaultPositiveCaption: 'Potvrdit',
    defaultNegativeCaption: 'Odmítnout',
  },
  thesesList: {
    filters: {
      search: 'Hledat…',
      select: 'Vyberte…',
      selected: 'Vybráno',
      reset: 'Resetovat filtry',
      expandMenu: 'Rozbalit menu',
    },
    newAssignment: 'Nové zadání',
    newAssignmentTooltip: 'Vytvořit nové zadání',
    editThesis: 'Upravit práci',
    exportCsv: 'Export do CSV',
    sortAscending: 'Seřadit vzestupně',
    sortDescending: 'Seřadit sestupně',
    sortReset: 'Zrušit řazení',
    emptyResultsMessage: 'Zadaným kriteriím neodpovídá žádná práce',
    myThesesTitle: 'Mé závěrečné práce',
    allThesesTitle: 'Vyhledávání závěrečných prací',
    officerThesesTitle: 'Vyhledávání závěrečných prací – referent SZZ',
    verifiedByFtOfficer: 'KZ',
    printApproved: 'KV',
    visitThesis: (thesisTitle: string): string => (`Přejít na závěrečnou práci „${thesisTitle}“`),
  },

  specialization: {
    specialization: 'Specializace',
    loadingSpecializations: 'Načítám specializace',
    errorLoadingSpecializations: 'Nastala chyba při načítání specializací',
  },

  topic: {
    topic: 'Téma',
    loadingTopic: 'Načítám téma',
    loadingTopics: 'Načítám témata',
    create: 'Tvorba tématu',
    edit: 'Editace tématu',
    delete: 'Smazat',
    errorMessages: {
      get: 'Nastala chyba při stahování tématu',
      getMetadata: 'Nastala chyba při načítání metadat témat',
      onlyAuthorCanEdit: 'Téma může upravovat pouze jeho autor.',
    },
    recommendedGroupTitle: 'Doporučeno pro',
    recommendedGroupHint: 'Studijní program(y) nebo studijní specializace pro které je toto téma'
      + ' zamýšleno. Zároveň lze vybrat hodnoty pouze z jednoho ze seznamů.',
    author: 'Autor',
    title: 'Název',
    description: 'Popis',
    email: 'e-mail',
    keywords: 'Klíčová slova',
    language: 'Jazyk tématu',
    languageOptions: thesisLanguageOptions,
    assignmentLanguageNote: 'Jazyk práce lze obvykle dohodnout s jejím vedoucím',
    selectLanguagePrompt: 'Vyberte prosím jazyk',
    specialization: 'Specializace',
    selectSpecializationPrompt: 'Vyberte prosím specializaci',
    studyDegree: 'Studijní program',
    studyDegreeOptions: {
      bachelor: 'Bakalářský',
      master: 'Magisterský',
    },
    selectStudyDegreePrompt: 'Vyberte prosím program',
    visibility: 'Viditelnost',
    visibilityOptions: {
      private: 'Soukromé',
      public: 'Veřejné',
      deleted: 'Smazané',
    },
    selectVisibilityPrompt: 'Vyberte prosím viditelnost tématu',
    thesesList: 'Seznam závěrečných prací',
    emptyThesesListMessage: 'S tímto tématem nejsou spojeny žádné závěrečné práce',
    actions: 'Další akce',
    createAssignment: 'Vytvořit zadání závěrečné práce',
    search: {
      title: {
        publicTopics: 'Vyhledávání témat',
        myTopics: 'Má témata',
      },
      filter: {
        author: 'Autor',
        authorNoResult: 'Žádná osoba nenalezena',
        authorPlaceholder: 'Vyhledat…',
        visibility: 'Viditelnost',
        specialization: 'Specializace',
        studyDegree: 'Studijní program',
        q: 'Hledat v názvu, popisu a klíčových slovech',

        noResult: 'Žádné výsledky',
        select: 'Vyberte…',
      },
      results: {
        author: 'Autor',
        published: 'Datum zveřejnění',
        specializations: 'Vhodné specializace',
        studyDegrees: 'Vhodné studijní programy',

        noResults: 'Žádné téma nevyhovuje Vašemu dotazu',
      },
    },
    errorGettingTopic: 'Nastala chyba při načítání tématu',
    missingStudentError: 'Závěrečná práce musí mít přiřazeného studenta',
    unsuitableSpecialization: 'Vybraný student nemá vhodnou specializaci',
    deleteActionModal: {
      title: 'Opravdu chcete toto téma smazat?',
      text: 'Tato akce je nevratná',
      positiveButton: 'Ano',
    },
  },

  auth: {
    gettingUserInfo: 'Získávám uživatelské údaje',
    loginSuccessful: 'Příhlášení proběhlo úspěšně',
    logoutSuccessful: 'Odhlášení proběhlo úspěšně',
    authenticationFailed: 'Nastala chyba při přihlašování',
    errorRetrievingOAuthToken: 'Nastala chyba při získávání přihlašovacího tokenu',
    errorGettingUserInfo: 'Nastala chyba při získávání informací o uživateli',
    errorLoggingOut: 'Nastala chyba při odhlašování',
  },
  accessControl: {
    noPermission: (action: string): string => (
      `Nemáte právo provést akci '${action}'`
    ),
    noPermissionDefault: 'Nemáte oprávnění k zobrazení této stránky',
    reportAlreadySubmitted: 'Posudek už byl dokončen',
  },

  filter: {
    reset: 'Resetovat filtry',
  },

  form: {
    all: 'Všechny',
    input: {
      characterCount: 'Počet znaků',
    },
    required: 'Pole je povinné',
    formInfoRequired: '* Pole je povinné',
    formUnsaved: 'neuloženo',
    formIsInvalid: 'Formulář obsahuje pole s neplatnou hodnotou',
    save: 'Uložit',
    saving: 'Ukládám',
    submit: 'Odevzdat',
    discardChanges: 'Zahodit změny',
    validation: {
      maxKeywordsCount: (count: number): string => `Povoleno je nanejvýš ${count} klíčových slov`,
      maxLength: (count: number): string => `Povoleno je nanejvýš ${count} znaků na klíčové slovo`,
      minLength: (count: number): string => `Je vyžadováno alespoň ${count} znaků`,
      minValue: (min: number): string => (`Minimální hodnota je ${min}`),
      maxValue: (max: number): string => (`Maximální hodnota je ${max}`),
      integerOnly: 'Pouze celé číslo',
      semesterCode: 'Kód semestru má tvar CYYS, kde C značí století (A = 20. století, B = 21. století), '
      + 'YY dvojčíslí roku a S semestr (1 = zimní, 2 = letní). Například zimní semestr roku 2010 se označuje B101.',
    },
    leaveBeforeSavingWarning: 'Máte neuložené změny. Opravdu chcete odejít?',
  },

  eventLog: {
    title: 'Seznam událostí',
    loadingEventLog: 'Načítám seznam událostí',
    getMoreEvents: 'Načíst další události',
    errorGettingEventLog: (resourceType: string, resourceId: number) => (
      `Nastala chyba při získávání seznamu změn objektu '${resourceType}' s identifikátorem '${resourceId}'`
    ),
    unknownAction: (action: string) => (
      `Neznámá akce '${action}'`
    ),
  },

  actionPanel: {
    action: {
      PROPOSE: 'Upravit a odeslat',
      OFFICER_APPROVE: 'Schválit',
      REVISE: 'Vrátit k revizi',
      ASSIGNEE_ACCEPT: 'Přijmout',
      ASSIGNEE_REJECT: 'Odmítnout',
      WITHDRAW: 'Přerušit schvalování',
      DELETE: 'Smazat zadání',
      ASSIGN_REVIEWER: 'Navrhnout oponenta',
      APPROVE_PRINTS: 'Potvrdit výtisky',
      SUBMIT_SUPERVISOR_REPORT: 'Vytvořit posudek',
      SUBMIT_REVIEWER_REPORT: 'Vytvořit posudek',
      SUPERSEDE: 'Zduplikovat a nahradit zadání',
    },
    panelId: {
      assignmentSupervisorProposal: {
        title: 'Odeslat zadání ke schválení',
        description: 'Zadání čeká na odeslání ke schválení. Ujistěte se, že student souhlasí '
        + 'se zněním zadání. Poté přejděte na dokončení úprav, kde máte k dispozici akci odeslání ke schválení.',
      },
      assignmentSpecOfficerApproval: {
        title: 'Schválení zadání',
        description: 'Zadání čeká na Vaše schválení. Pokud nesouhlasíte se zněním, vraťte zadání '
        + 'k přepracování a do komentáře uveďte, jaké změny požadujete.',
      },
      assignmentSupervisorWaitingForApproval: {
        title: 'Zadání čeká na schválení',
        description: 'Nyní musí být schváleno referentem specializace a přijato studentem. '
        + 'Mezitím máte možnost zadání přepracovat. Také můžete přerušit schvalování a vrátit zadání do stavu Koncept.',
      },
      assignmentSupervisorRevision: {
        title: 'Revize zadání',
        description: 'Pokud referent/ka specializace vrátil/a Vaše zadání k přepracování, je nutné zadání upravit. '
        + 'Pokud jste inicioval/a přepracování Vy, nyní máte možnost zadání upravit. Po úpravě odešlete zadání znovu '
        + 'ke schválení. Také máte možnost přerušit schvalování.',
      },
      assignmentAssigneeAcceptance: {
        title: 'Přijetí zadání',
        description: 'Zadání závěrečné práce čeká na Vaše závazné přijetí k vypracování. Pokud nesouhlasíte se zněním '
        + 'zadání, domluvte se s vedoucím. Pokud na zadání nechcete pracovat, odmítněte.',
      },
      assignmentAssigneeAcceptanceSupervisor: {
        title: 'Přijetí zadání',
        description: 'Zadání závěrečné práce čeká na závazné přijetí ze strany studenta. '
        + 'Pokud si přejete udělat změny v zadání, můžete přerušit schvalování.',
      },
      assignmentAssigneeSecondAcceptance: {
        title: 'Přijetí zadání (po zamítnutí)',
        description: 'Zadání jste odmítl/a a z Vaší strany se momentálně žádná akce nevyžaduje. Stále však máte '
        + 'možnost zadání závazně přijmout.',
      },
      assignmentRejectedByStudent: {
        title: 'Zadání zamítnuto studentem',
        description: 'Student/ka odmítl/a zadání, ale stále má možnost zadání přijmout, pokud se domluvíte. Chcete-li '
        + 'zadání upravit a přidělit někoho jiného, přerušte schvalování.',
      },
      thesisSupervisorReviewerProposal: {
        title: 'Navržení oponenta',
        description: 'Práce čeká na přidělení oponenta. Můžete navrhnout vhodného kandidáta.',
      },
      thesisAssigneeResubmission: {
        title: 'Znovuodevzdání práce',
        description: 'Pokud bude vedoucí mít připomínky k Vaší práci, máte možnost práci upravit a nahrát novou verzi. '
        + 'Zároveň máte právo připomínky nezapracovávat, ale pravděpodobně se to promítne v posudku vedoucího.',
      },
      thesisSupervisorReport: {
        title: 'Odevzdání posudku vedoucího',
        description: 'Práce čeká na Váš posudek.',
      },
      thesisReviewerReport: {
        title: 'Odevzdání posudku oponenta',
        description: 'Práce čeká na Váš posudek.',
      },
      reviewerProposalReviewerAcceptance: {
        title: 'Přijetí oponentury',
        description: 'Byl jste navržen na oponenturu této práce. Přijetí je závazné a zájemců může být více, proto '
        + 'neodkládejte toto rozhodnutí na později.',
      },
      thesisSupersession: {
        title: 'Nahradit zadání po neúspěšné obhajobě',
        description: 'Pokud student neobhájil a přeje si pracovat na stejném zadání, máte jako referent/ka SZZ možnost '
        + 'vytvořit novou instanci zadání zduplikováním a nahrazením současného zadání.',
      },
    },
    form: {
      label: 'Komentář se zdůvodněním',
      btn: 'Odeslat',
    },
  },
  footer: {
    authorshipAndMaintenanceNote: 'Vytvořilo a spravuje Oddělení ICT',
    copyrightNote: (year: number): string => `© 2021${year > 2021 ? '-' + String(year) : ''} `
    + 'Fakulta informačních technologií, České vysoké učení technické v Praze',
    personRegistration: 'Registrace osob',
    issuesLinkCaption: 'Nahlásit chybu',
    manualLinkCaption: 'Manuál',
    stateExamsLinkCaption: 'Státnice',
  },
  misc: {
    all: 'Vše',
    no: 'Ne',
    yes: 'Ano',
  },
}

export default cs
